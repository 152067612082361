<template>
	<div class="w-full p-6 border-b border-gray">
		<div class="flex items-center justify-between cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<div class="flex items-center">
					<span class="mr-6 icon-list"></span>
				</div>
				<h4 class="text-base font-semibold text-gray-dark">ITEM LIST</h4>
			</div>
			<div>
				<p :class="collapse.itemList ? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
			</div>
		</div>
		<div class="w-full mb-6"
			v-show="collapse.itemList">
			<div class="">
				<TableInput></TableInput>
			</div>
			<div class="flex justify-center mt-6">
				<p @click="addItemList"
					class="underline cursor-pointer text-secondary hover:text-secondary-dark">Add New Item</p>
			</div>
		</div>
	</div>
</template>

<script>
import TableInput from "@/modules/module-rfq/pages/createNewQuotation/tableInput.vue";

export default {
	name: "ItemList",

	computed: {
		quotationInformation: {
			get() {
				return this.$store.state.rfq.quotationInformation;
			},
			set(value) {
				this.$store.commit('rfq/SET_QUOTATION_INFORMATION', value);
			}
		},
		rfqItemLists: {
			get() {
				return this.$store.state.rfq.rfqItemLists
			},
			set(value) {
				this.$store.commit('rfq/SET_RFQ_ITEM_LISTS', value)
			}
		},
		collapse() {
			return this.$store.state.rfq.collapse
		},
	},
	methods: {
		addItemList() {
			const newItemList = {
				rfqItemListId: null,
				rfqId: "0",
				jenis: "",
				productLayanan: "",
				subProduct: "",
				description: "",
				lokasi: "",
				qty: "1",
				uom: "",
			}
			this.rfqItemLists = [...this.rfqItemLists, newItemList]
			const el = this.$el.querySelector('#tableInputOverflow')
			if (el) {
				el.scroll({ top: el.scrollHeight, behavior: 'smooth' });
			}
		},
		changeCollapse() {
			this.$emit('collapse')
		}
	},
	mounted() {
		if (this.$route.params.quotationId) {
			this.$store.dispatch('rfq/getQuotationItemList', this.$route.params.quotationId)
		}
	},
	components: {
		TableInput,
	}
}
</script>