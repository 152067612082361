<template>
	<div class="w-full p-6 border-b border-gray">
		<div class="flex justify-between mb-6 cursor-pointer" @click="changeCollapse">
			<div class="flex">
				<div class="flex items-center">
					<span class="mr-6 text-lg icon-list text-gray"></span>
				</div>
				<h5 class="text-base font-semibold text-gray-dark">Quotation Information</h5>
			</div>
			<div>
				<p :class="collapse.quotationInformation ? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
			</div>
		</div>
		<div class="w-full" v-show="collapse.quotationInformation">
			<div class="grid w-full grid-cols-1 gap-6 mb-6 md:grid-cols-3 lg:grid-cols-4">
				<div class="flex-wrap w-full">
					<label class="text-sm text-gray">New Project?</label>
					<div class="flex items-center mb-6 h-2/3">
						<GroupRadioButton class="mt-1"
							:options="['YA', 'TIDAK']"
							:selected="selected"
							name="newProject"
							@change="updateSelect($event)" />
					</div>
				</div>
				<div class="w-full">
					<AutocompleteSolutip placeholder="(Search)"
						@updateInput="inputSearch($event)"
						@updateAutoComplete="updateAutoCompleteVendorNameProjectId($event)"
						@focus="focusProjectId"
						@blur="blurProjectId"
						:options="listProjectId"
						label="Project ID"
						icon-left="icon-search text-xl text-secondary"
						textField="projectId"
						valueField="projectId"
						:isLoading="isLoadingProject"
						:disabled="quotationInformation.isNewProject"
						v-model="projectIdLocalState"
						:error="!!errorRequired['Project ID']"
						:mandatory="!quotationInformation.isNewProject"
						>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorRequired['Project ID'] ? MESSAGE_EMPTY_FIELD: '' }}</p>
						</template>
					</AutocompleteSolutip>
				</div>
				<div class="w-full" v-if="!quotationInformation.isNewProject">
					<AutocompleteSolutip placeholder="(Search)"
						@updateInput="inputSearchVendorRfq($event)"
						@updateAutoComplete="updateAutoCompleteVendorRfq($event)"
						@focus="focusVendorRfq"
						@blur="blurVendorRfq"
						:options="listVendorRfq"
						label="Vendor"
						icon-left="icon-search text-xl text-secondary"
						textField="vendorName"
						valueField="vendorId"
						:isLoading="isLoadingVendor"
						:disabled="quotationInformation.isNewProject || !quotationInformation.projectId"
						v-model="vendorRfqLocalState.vendorName"
						:error="!!errorRequired['Vendor']"
						:mandatory="!quotationInformation.isNewProject"
						>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorRequired['Vendor'] ? MESSAGE_EMPTY_FIELD: '' }}</p>
						</template>
					</AutocompleteSolutip>
				</div>
				<div class="w-full" v-if="!quotationInformation.isNewProject">
					<AutocompleteSolutip placeholder="(Search)"
						@updateInput="inputSearchLocalPartner($event)"
						@updateAutoComplete="updateAutoCompleteLocalPartner($event)"
						@focus="focusLocalPartner"
						@blur="blurLocalPartner"
						:options="listLocalPartner"
						label="Local Partner"
						icon-left="icon-search text-xl text-secondary"
						textField="vendorName"
						valueField="vendorId"
						:isLoading="isLoadingVendor"
						:disabled="!vendorRfqLocalState?.vendorName || (vendorRfqLocalState?.localPartners?.length === 0)"
						v-model="localPartnerLocalState"
						:error="!!errorRequired['Local Partner']"
						:mandatory="vendorRfqLocalState?.localPartners?.length > 0"
						>
						<template #message>
							<p class="mt-1 text-xs text-error">{{ errorRequired['Local Partner'] ? MESSAGE_EMPTY_FIELD: '' }}</p>
						</template>
					</AutocompleteSolutip>
				</div>
				<div class="w-full">
					<ValidationProvider name="Direct Approver"
						v-slot="{ errors }">
						<AutocompleteSolutip @updateInput="inputSearchDirectApprover($event)"
							@updateAutoComplete="updateDirectApprover($event)"
							@focus="focusDirectApprover"
							@blur="blurDirectApprover"
							:options="approverUkkp"
							v-model="directApproverLocalState"
							iconLeft="icon-search text-xl text-gray-light"
							placeholder="(Search)"
							mandatory
							textField="name"
							label="Direct Approver"
							:isLoading="isLoadingDirectApprover"
							:error="!!errors[0] || errorRequired['Direct Approver']"
							>
							<template #message>
								<p class="mt-1 text-xs text-error">{{ errors[0] || (errorRequired['Direct Approver'] ? MESSAGE_EMPTY_FIELD: '')}}</p>
							</template>
						</AutocompleteSolutip>
					</ValidationProvider>
				</div>

			</div>
			<div class="w-full mb-6">
				<ValidationProvider name="Project Name"
					rules="max:200"
					v-slot="{errors}">
					<InputGista label="Project Name"
						size="large"
						type="text"
						:disabled="!quotationInformation.isNewProject"
						v-model="quotationInformation.projectName"
						:error="!!errors[0] || errorRequired['Project Name']"
						@input="updateError('Project Name')"
						mandatory
						class="w-full text-gray-light">
						<template #message>
							<p>{{errors[0] || (errorRequired['Project Name'] ? MESSAGE_EMPTY_FIELD: '')}}</p>
						</template>
					</InputGista>
				</ValidationProvider>
			</div>
			<div class="w-full">
				<div class="line-horizontal"></div>
			</div>
			<div class="grid w-full grid-cols-1 gap-6 mb-6 md:grid-cols-3 lg:grid-cols-4">
				<div class="w-full">
					<ValidationProvider name="Vendor Name"
						v-slot="{ errors }">
						<AutocompleteSolutip placeholder="(Search)"
							@updateInput="inputSearchVendor($event)"
							@updateAutoComplete="updateAutoCompleteVendorName($event)"
							@focus="focusVendorName"
							@input="updateError('Vendor Name')"
							:options="vendorList"
							v-model="quotationInformation.vendorName"
							:isLoading="isLoadingVendor"
							label="Vendor Name"
							:mandatory="true"
							:error="!!errors[0] || errorRequired['Vendor Name']"
							textField="vendorName">
							<template #message>
								<p>{{ errors[0]  || (errorRequired['Vendor Name'] ? MESSAGE_EMPTY_FIELD: '')}}</p>
							</template>
						</AutocompleteSolutip>
					</ValidationProvider>
				</div>
				<div class="w-full">
					<ValidationProvider name="Vendor Email"
						rules="email"
						v-slot="{ errors }">
						<InputSolutip type="text"
							label="Vendor Email"
							:mandatory="true"
							size="large"
							v-model="quotationInformation.vendorEmail"
							@input="updateError('Vendor Email')"
							:error="!!errors[0] || errorRequired['Vendor Email']"
							>
							<template #message>
								<p>{{ errors[0]  || (errorRequired['Vendor Email'] ? MESSAGE_EMPTY_FIELD : '')}}</p>
							</template>
						</InputSolutip>
					</ValidationProvider>
				</div>
				<div class="w-full">
					<InputGista label="No. RFQ"
						size="large"
						type="text"
						:disabled="true"
						:value="editRfq ? quotationInformation.noRfq : '(auto-generate)'" />
				</div>
				<div class="w-full">
					<InputGista label="Year"
						size="large"
						type="text"
						v-model="quotationInformation.tahun"
						:disabled="true" />
				</div>
				<div class="w-full">
					<ValidationProvider name="Budget Spent"
						rules="maxBudgetSpent"
						v-slot="{errors}">
						<InputSolutip label="Budget Spent (IDR)"
							placeholder="1.000.000.000"
							size="large"
							type="text"
							:error="!!errors[0] || errorRequired['Budget Spent']"
							mandatory
							v-model="quotationInformation.budgetSpent"
							@change="() => errorRequired['Budget Spent'] = false"
							@keypress="validationBudgetSpent"
							@input="inputBudgetSpent"
							@handleInput="handleInputBudgetSpent">
							<template #message>
								<p>{{errors[0] || (errorRequired['Budget Spent'] ? MESSAGE_EMPTY_FIELD: '')}}</p>
							</template>
						</InputSolutip>
					</ValidationProvider>
				</div>
				<div class="w-full">
					<ValidationProvider name="Kode Wbs Level 1"
						rules="max:200"
						v-slot="{ errors }">
						<AutocompleteSolutip @updateInput="inputSearchWBS"
							@updateAutoComplete="updateWBS"
							:options="WBS"
							placeholder="(Search)"
							@focus="focusKodeWBSLev1"
							@blur="blurKodeWBSLev1"
							iconLeft="icon-search text-xl text-gray-light"
							v-model="kodeWbsLev1LocalState"
							textField="wbsElement"
							label="Kode WBS Level 1"
							:isLoading="isLoadingWBS"
							:error="!!errors[0] || errorRequired['Kode Wbs Level 1']"
							mandatory>
							<template #message>
								<p>{{errors[0] || (errorRequired['Kode Wbs Level 1'] ? MESSAGE_EMPTY_FIELD: '')}}</p>
							</template>
						</AutocompleteSolutip>
					</ValidationProvider>
				</div>


			</div>
			<div class="w-full mb-6">
				<ValidationProvider name="Request Title"
					rules="required|max:200"
					v-slot="{ errors }">
					<InputGista label="Request Title"
						size="large"
						type="text"
						v-model="quotationInformation.judulRfq"
						:error="!!errors[0] || errorRequired['Request Title']"
						mandatory
						@input="updateError('Request Title')"
						class="w-full">
						<template #message>
							<p>{{ errors[0] || (errorRequired['Request Title'] ? MESSAGE_EMPTY_FIELD : '') }}</p>
						</template>
					</InputGista>
				</ValidationProvider>
			</div>
			<div class="w-full mb-6">
				<ValidationProvider name="Notes"
					rules="max:300"
					v-slot="{ errors }">
					<TextareaGista label="Notes (Optional)"
						v-model="quotationInformation.notes"
						class="w-full"
						:error="!!errors[0] || error.notes.value" >
						<template #message>
							<p>{{ errors[0] || error.notes.message }}</p>
						</template>
					</TextareaGista>
				</ValidationProvider>
			</div>
		</div>
	</div>
</template>

<script>
import GroupRadioButton from "@/core/components/custom/GroupRadioButton.vue";
import AutocompleteSolutip from '@/core/components/custom/Autocomplete.vue'
import InputSolutip from '@/core/components/custom/Input.vue'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'



export default {
	name: "QuotationInformation",
	props: ["errorRequired", 'editRfq'],
	data() {
		return {
			selected: 'YA',
			projectIdLocalState: '',
			selectedValues: {
				selectedVendorName: '',
				selectedLocalPartner: ''
			},
			vendorRfqLocalState: {},
			localPartnerLocalState: '',
			directApproverLocalState: '',
			kodeWbsLev1LocalState: '',
			timeout: null,
			vendorListFixed: [],
			isLoadingVendor: false,
			isLoadingProject: false,
			isLoadingDirectApprover: false,
			isLoadingWBS: false,
			MESSAGE_EMPTY_FIELD,
			newProjectValueTrue: 'YA',
			newProjectValueFalse: 'TIDAK'
		}
	},
	computed: {
		quotationInformation: {
			get() {
				return this.$store.state.rfq.quotationInformation
			},
			set(value) {
				this.$store.commit('rfq/SET_QUOTATION_INFORMATION', value)
			}
		},
		listProjectId() {
			return this.$store.state.rfq.rfqProjectList
		},
		listVendorRfq() {
			return this.$store.state.rfq.rfqVendorList
		},
		listLocalPartner: {
			get() {
				return this.$store.state.rfq.rfqLocalPartnerList
			},
			set(value) {
				this.$store.commit('rfq/SET_RFQ_LOCAL_PARTNER_LIST', value)
			}
		},
		vendorList() {
			return this.$store.state.rfp.vendorList
		},
		vendorListAll() {
			return this.$store.state.vendor.vendorListAll
		},
		approverUkkp() {
			return this.$store.state.rfp.approverUkkp;
		},
		projectLogStatus() {
			//filter without project canceled
			return this.$store.state.projectLog.projectLogStatus.content.filter(e => e.currentStatus !== 'Project Canceled')
		},
		WBS() {
			return this.$store.state.rfp.WBS
		},
		menuDashboard() {
			return this.$store.getters['dashboard/menuDashboard']
		},
		collapse() {
			return this.$store.state.rfq.collapse
		},
		error: {
			get() {
				return this.$store.state.errorRfq.error
			},
			set(value) {
				this.$store.commit('errorRfq/SET_ERROR', value)
			}
		}
	},
	watch: {
		'quotationInformation.projectId'() {
			this.projectIdLocalState = this.quotationInformation.projectId
		},
		'quotationInformation.directApproverName'() {
			this.directApproverLocalState = this.quotationInformation.directApproverName
		},
		'quotationInformation.kodeWbsLev1'() {
			this.kodeWbsLev1LocalState = this.quotationInformation.kodeWbsLev1
		}
	},
	methods: {
		updateError(e) {
			this.$emit('updateError', e, false) 
		},
		updateSelect(e) {
			if (e === 'YA') {
				this.quotationInformation.isNewProject = true
				this.selected = 'YA'
				this.quotationInformation.projectId = ''
				this.quotationInformation.projectName = ''
				this.quotationInformation.vendorName = ''
				this.quotationInformation.vendorEmail = ''
				this.projectIdLocalState = ''
				this.vendorRfqLocalState = {}
				this.selectedValues.selectedVendorName = ''
				this.selectedValues.selectedLocalPartner = ''
				this.localPartnerLocalState = ''
				this.$store.dispatch('rfq/resetStateRfqItemLists')
			} else {
				this.quotationInformation.isNewProject = false
				this.selected = 'TIDAK'
			}
		},
		async inputSearchDirectApprover(event) {
			this.isLoadingDirectApprover = true
			await this.$store.dispatch('rfp/getApproverUkkp', { role: this.menuDashboard.applicationRoleName, name: event, project: 'RFQ' })
			this.isLoadingDirectApprover = false
		},
		async focusDirectApprover() {
			this.isLoadingDirectApprover = true
			await this.$store.dispatch('rfp/getApproverUkkp', { role: this.menuDashboard.applicationRoleName, name: this.directApproverLocalState, project: 'RFQ' })
			this.isLoadingDirectApprover = false
		},
		async blurDirectApprover() {
			if (!this.directApproverLocalState) {
				this.quotationInformation.directApproverName = ''
				this.quotationInformation.directApproverId = ''
			}
			this.directApproverLocalState = this.quotationInformation.directApproverName

		},
		updateDirectApprover(event) {
			this.updateError('Direct Approver')
			this.quotationInformation.directApproverId = event.userName
			this.quotationInformation.directApproverName = event.name
			this.directApproverLocalState = event.name
		},
		async focusVendorName() {
			this.isLoadingVendor = true
			await this.$store.dispatch('rfp/getVendor', { vendorName: this.quotationInformation.vendorName })
			this.isLoadingVendor = false
		},
		async inputSearch(e) {
			this.isLoadingProject = true
			await this.$store.dispatch('rfq/getQuotationProjectList', { query: e })
			this.isLoadingProject = false
		},
		async inputSearchVendorRfq(e) {
			this.isLoadingVendor = true
			await this.$store.dispatch('rfq/getQuotationProjectPrepare', { projectId: this.projectIdLocalState, search: e })
			this.isLoadingVendor = false
		},
		async inputSearchLocalPartner(e) {
			this.isLoadingVendor = true
			await this.$store.dispatch('rfq/getQuotationProjectPrepareLocalPartner', { projectId: this.projectIdLocalState, principalVendorId: this.vendorRfqLocalState.vendorId, search: e })
			this.isLoadingVendor = false
		},
		async focusProjectId() {
			this.isLoadingProject = true
			await this.$store.dispatch('rfq/getQuotationProjectList', { query: this.projectIdLocalState })
			this.isLoadingProject = false
		},
		async focusVendorRfq() {
			this.isLoadingVendor = true
			await this.$store.dispatch('rfq/getQuotationProjectPrepare', { projectId: this.projectIdLocalState, search: this.vendorRfqLocalState?.vendorName  })
			this.isLoadingVendor = false
		},
		async focusLocalPartner() {
			this.isLoadingVendor = true
			console.log(this.vendorRfqLocalState.vendorId);
			await this.$store.dispatch('rfq/getQuotationProjectPrepareLocalPartner', { projectId: this.projectIdLocalState, principalVendorId: this.vendorRfqLocalState.vendorId, search: this.localPartnerLocalState  })
			this.isLoadingVendor = false
		},
		blurProjectId() {
			if (!this.projectIdLocalState) {
				this.quotationInformation.projectId = ''
				this.quotationInformation.projectName = ''
				this.quotationInformation.filterVendorId = ''
				this.quotationInformation.filterLocalPartnerVendorId = ''
				this.quotationInformation.vendorName = ''
				this.quotationInformation.vendorEmail = ''
				this.vendorRfqLocalState = {}
				this.selectedValues.selectedVendorName = ''
				this.selectedValues.selectedLocalPartner = ''
				this.localPartnerLocalState = ''
				this.quotationInformation.vendorId = ''
				this.$store.dispatch('rfq/resetStateRfqItemLists')
			}
			this.projectIdLocalState = this.quotationInformation.projectId
		},
		blurVendorRfq() {
			if (!this.vendorRfqLocalState?.vendorName) {
				this.quotationInformation.projectName = ''
				this.quotationInformation.filterVendorId = ''
				this.quotationInformation.filterLocalPartnerVendorId = ''
				this.quotationInformation.vendorName = ''
				this.quotationInformation.vendorEmail = ''
				this.vendorRfqLocalState = {}
				this.selectedValues.selectedVendorName = ''
				this.selectedValues.selectedLocalPartner = ''
				this.localPartnerLocalState = ''
				this.quotationInformation.vendorId = ''
				this.$store.dispatch('rfq/resetStateRfqItemLists')
				this.updateError('Local Partner')
				return;
			}
			this.vendorRfqLocalState.vendorName = this.selectedValues.selectedVendorName
		},
		blurLocalPartner() {
			if (!this.localPartnerLocalState) {
				this.quotationInformation.projectName = ''
				this.quotationInformation.filterLocalPartnerVendorId = ''
				this.selectedValues.selectedLocalPartner = ''
				this.quotationInformation.vendorId = ''
				this.quotationInformation.vendorName = ''
				this.quotationInformation.vendorEmail = ''
				this.$store.dispatch('rfq/resetStateRfqItemLists')
			}
			this.localPartnerLocalState = this.selectedValues.selectedLocalPartner
		},
		async inputSearchVendor(e) {
			this.quotationInformation.vendorId = ''
			this.quotationInformation.isNewVendor = true
			this.isLoadingVendor = true
			await this.$store.dispatch('rfp/getVendor', { vendorName: e })
			this.isLoadingVendor = false
		},
		updateAutoCompleteVendorNameProjectId(e) {
			this.quotationInformation.projectId = e.projectId
			this.quotationInformation.projectName = ''
			this.projectIdLocalState = e.projectId
			this.vendorRfqLocalState = {}
			this.selectedValues.selectedVendorName = ''
			this.selectedValues.selectedLocalPartner = ''
			this.quotationInformation.vendorName = ''
			this.quotationInformation.vendorEmail = ''
			this.localPartnerLocalState = ''
			// this.updateError('Project Name')
			this.$store.dispatch('rfq/resetStateRfqItemLists')
			this.updateError('Project ID')
		},
		async updateAutoCompleteVendorRfq(e) {
			this.quotationInformation.projectName = e.projectName
			this.quotationInformation.filterVendorId = e.vendorId
			this.selectedValues.selectedVendorName = e.vendorName
			this.vendorRfqLocalState = e
			this.localPartnerLocalState = ''
			this.listLocalPartner = []
			this.updateError('Vendor')
			if (e?.localPartners?.length > 0) {
				this.$store.dispatch('rfq/resetStateRfqItemLists')
			}
			this.updateError('Vendor Name')
			this.updateError('Vendor Email')
			this.updateError('Project Name')
			this.quotationInformation.vendorName = e.vendorName
			this.quotationInformation.vendorEmail = e.vendorEmail
			this.quotationInformation.vendorId = e.vendorId
			if (e.rfqItemLists.length > 0) {
				this.$store.commit('rfq/SET_RFQ_ITEM_LISTS', e.rfqItemLists)
			}
			this.$emit('setErrorItemList')
		},
		async updateAutoCompleteLocalPartner(e) {
			this.quotationInformation.projectName = e.projectName
			this.quotationInformation.filterLocalPartnerVendorId = e.vendorId
			this.selectedValues.selectedLocalPartner = e.vendorName
			this.localPartnerLocalState = e.vendorName
			this.quotationInformation.vendorName = e.vendorName
			this.quotationInformation.vendorEmail = e.vendorEmail
			this.quotationInformation.vendorId = e.vendorId
			this.updateError('Project Name')
			this.updateError('Vendor Name')
			this.updateError('Vendor Email')
			this.updateError('Local Partner')
			if (e.rfqItemLists.length > 0) {
				this.$store.commit('rfq/SET_RFQ_ITEM_LISTS', e.rfqItemLists)
			}
			this.$emit('setErrorItemList')
		},
		updateAutoCompleteVendorName(e) {
			this.updateError('Vendor Name')
			if(e.vendorEmail) this.updateError('Vendor Email')
			this.quotationInformation = { ...this.quotationInformation, vendorName: e.vendorName, vendorId: e.vendorId, isNewVendor: e.isNewVendor, disabled: true, vendorEmail: e.vendorEmail }
		},
		async inputSearchWBS(event) {
			this.isLoadingWBS = true
			await this.$store.dispatch('rfp/getWBS', { element: event })
			this.isLoadingWBS = false
		},
		updateWBS(event) {
			this.updateError('Kode Wbs Level 1')
			this.quotationInformation.kodeWbsLev1 = event.wbsElement
			this.kodeWbsLev1LocalState = event.wbsElement
		},
		async focusKodeWBSLev1() {
			this.isLoadingWBS = true
			await this.$store.dispatch('rfp/getWBS', { element: this.kodeWbsLev1LocalState })
			this.isLoadingWBS = false
		},
		blurKodeWBSLev1() {
			if (!this.kodeWbsLev1LocalState) {
				this.quotationInformation.kodeWbsLev1 = ''
			}
			this.kodeWbsLev1LocalState = this.quotationInformation.kodeWbsLev1
		},
		validationBudgetSpent(e) {
			let keyCode = e.keyCode
			if ((keyCode > 47 && keyCode < 58) || keyCode === 46) {
				return
			}
			e.preventDefault();
		},
		inputBudgetSpent() {
			this.updateError('Budget Spent')
			const formatted = formatThousandSeparate(this.quotationInformation.budgetSpent.split('.').join(''), 'idr')
			this.quotationInformation.budgetSpent = formatted
		},
		handleInputBudgetSpent(e) {
			const el = e.target;
			const formatted = formatThousandSeparate(el.value.split('.').join(''), 'idr')
			const count = formatted?.split('.').length
			const count2 = el.value?.split('.').length
			let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
			this.$nextTick(() => {
				el.setSelectionRange(sel, sel);
			});
		},
		changeCollapse() {
			this.$emit('collapse')
		}
	},
	async mounted() {
		//get manager direct approver
		if (!this.quotationInformation.directApproverId && !this.$route.params.quotationId) {
			const responseMgr = await this.$store.dispatch('rfq/getRfqMgr')
			this.quotationInformation.directApproverId = responseMgr?.data?.userId
			this.quotationInformation.directApproverName = responseMgr?.data?.userName
		}
		if (this.$route.params.quotationId) {
			await this.$store.dispatch('rfq/getQuotationInformation', this.$route.params.quotationId)
			if (!this.quotationInformation.isNewProject) {
				this.updateSelect(this.newProjectValueFalse)
			}
		}
		//get all vendor list to check email
		this.$store.dispatch('vendor/getVendor')
		//get recent year
		this.quotationInformation.tahun = new Date().getFullYear().toString()
		if (this.quotationInformation.filterVendorId) {
			await this.$store.dispatch('rfq/getQuotationProjectPrepare', { projectId: this.projectIdLocalState })
			this.vendorRfqLocalState = this.listVendorRfq.find(e => e.vendorId === this.quotationInformation.filterVendorId)
			this.quotationInformation.projectName = this.vendorRfqLocalState.projectName
			this.selectedValues.selectedVendorName = this.vendorRfqLocalState.vendorName
			if (this.quotationInformation.filterLocalPartnerVendorId) {
				await this.$store.dispatch('rfq/getQuotationProjectPrepareLocalPartner', { projectId: this.projectIdLocalState, principalVendorId: this.quotationInformation.filterVendorId })
				const localPartnerFind = this.listLocalPartner.find(e => e.vendorId === this.quotationInformation.filterLocalPartnerVendorId)
				this.quotationInformation.projectName = localPartnerFind.projectName
				this.selectedValues.selectedLocalPartner = localPartnerFind.vendorName
				this.localPartnerLocalState = localPartnerFind.vendorName
			}
		}
	},
	components: {
		GroupRadioButton,
		AutocompleteSolutip,
		InputSolutip,
	}
}
</script>