<template>
	<MainLayout>
		<div class="w-full ">
			<div class="w-full mb-6 rfq">
				<div class="">
					<div class="flex items-center w-full mb-6 title my-11">
						<div v-if="$route.params.quotationId">
							<span class="text-2xl font-bold cursor-pointer icon-arrow-left normal text-gray"
								@click="cancelQuotation"></span>
						</div>
						<div v-else>
							<span class="text-2xl font-bold cursor-pointer icon-arrow-left normal text-gray"
								@click="changeIsOpen(true, 'modalCancel')"></span>
							<ModalConfirm 
								title="Batalkan Pengajuan?" 
								message="Apakah Anda Yakin Tidak Melanjutkan Pengajuan Request Ini?"
								icon="rejected" textButton="Cancel Quotation" 
								:isOpen="isOpenModalCancelQuotation" 
								@changeIsOpen="changeIsOpen($event, 'modalCancel')" 
								@click="cancelQuotation"
								colorButton="error">
							</ModalConfirm>
						</div>
						<h4 class="pl-3 text-3xl font-semibold">{{$route.name === editRfq? 'Edit Quotation':'Create New Quotation'}}</h4>
					</div>
					<div class="w-full bg-white rounded-2xl shadow-gray-sm">
						<ValidationObserver ref="form">

							<QuotationInformation :editRfq="$route.name === editRfq" @collapse="changeCollapse($event, 'quotationInformation')" @setErrorItemList="setErrorItemList" @updateError="updateError" :errorRequired="errorRequired"/>
							<ItemList @collapse="changeCollapse($event, 'itemList')"/>
							<div class="flex w-full p-6 flex-wrap-reverse">
								<div class="sm:flex-1 flex-none sm:w-max w-full">
									<ButtonGista 
										v-if="$route.name !== editRfq"
										type="secondary"
										color="primary"
										customClass="w-full sm:w-60 mt-3.5 sm:mt-0"
										@click="changeIsOpen(true, 'modalSaveAsDraft')">
										Save As Draft
									</ButtonGista>
								</div>
								<div class="flex-none sm:w-max w-full">
									<ButtonGista
										type="primary"
										@click="changeIsOpen(true, 'modalSubmit')"
										customClass="w-full sm:w-60"
										>
										{{$route.name !== editRfq ? 'Submit Quotation':'Save Changes'}}
									</ButtonGista>
								</div>
							</div>
							<ModalConfirm 
								title="Simpan sebagai Draft" 
								message="Anda dapat melanjutkan proyek di tab ‘Draft’" 
								icon="saved" 
								textButton="Save As Draft" 
								:isOpen="isOpenModalSaveAsDraft" 
								@changeIsOpen="changeIsOpen($event, 'modalSaveAsDraft')" 
								@click="saveAsDraft"
								typeButton="secondary">
							</ModalConfirm>
							<ModalConfirm 
								title="Apakah Anda Yakin?" 
								:message="`Pastikan seluruh informasi sudah benar sebelum ${$route.name !== editRfq ? 'submit':'save'}`" 
								icon="submitted" 
								:textButton="$route.name !== editRfq ? 'Submit Quotation':'Save Changes'" 
								:isOpen="isOpenModalSubmit" 
								@changeIsOpen="changeIsOpen($event, 'modalSubmit')" 
								@click="submitQuotation">
							</ModalConfirm>
						</ValidationObserver>

					</div>
				</div>
			</div>
		</div>
	</MainLayout>
</template>

<script>
import MainLayout from "@/core/layouts/MainLayout.vue";
import QuotationInformation from "@/modules/module-rfq/pages/createNewQuotation/QuotationInformation.vue";
import ItemList from "@/modules/module-rfq/pages/createNewQuotation/ItemList.vue"
import ModalConfirm from "@/core/components/modal/Confirm.vue"
import { MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
import { PATH_ROOT, EDITRFQ } from '@/core/constant/routeName'
import { MESSAGE_SUCCESS_SAVE_AS_DRAFT, MESSAGE_SUCCESS_SUBMIT } from '@/core/constant/successMessage'

export default {
	name: "RFQ",
	data() {
		return {
			editRfq: EDITRFQ,
			errorRequired: {
				'Direct Approver': false,
				'Project Name': false,
				'Vendor Name': false,
				'Vendor Email': false,
				'Budget Spent': false,
				'Kode Wbs Level 1': false,
				'Request Title': false,
				'Project ID': false,
				'Vendor': false,
				'Local Partner': false,
			},
			isOpenModalCancelQuotation: false,
			isOpenModalSaveAsDraft: false,
			isOpenModalSubmit: false,
		}
	},
	computed: {
		menuRfqActionSubmit() {
			return this.$store.getters['dashboard/menuRfqActionSubmit']
		},
		menuRFQ() {
			return this.$store.getters['dashboard/menuRFQ']
		},
		activeButton() {
			return this.$store.state.projectLog.activeButton
		},
		quotationInformation() {
			return this.$store.state.rfq.quotationInformation
		},
		rfqItemLists() {
			return this.$store.state.rfq.rfqItemLists
		},
		errorRequiredItemList: {
			get() {
				return this.$store.state.errorRfq.errorRequiredItemList
			},
			set(value) {
				this.$store.commit('errorRfq/SET_ERROR_ITEM_LIST', value)
			}
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		collapse: {
			get() {
				return this.$store.state.rfq.collapse
			},
			set(value) {
				this.$store.commit('rfq/SET_COLLAPSE', value)
			}
		},
		error: {
			get() {
				return this.$store.state.errorRfq.error
			},
			set(value) {
				this.$store.commit('errorRfq/SET_ERROR', value)
			}
		},
		rfqLocalPartnerList() {
			return this.$store.state.rfq.rfqLocalPartnerList
		},
	},
	methods: {
		updateError(name, boolean) {
			this.errorRequired[name] = boolean
		},
		checkInput(e) {
			return e.jenis || e.productLayanan || e.subProduct || e.description || e.lokasi || e.uom
		},
		checkEmptyField(e) {
			return !e.jenis || !e.productLayanan || !e.uom || (e.qty === null || e.qty === '')
		},
		setEmpty(e, i) {
			if(!this.errorRequiredItemList[i]) {
				this.errorRequiredItemList.push({
					'Jenis': !e.jenis,
					'Product Layanan': !e.productLayanan,
					'Qty': (e.qty === null || e.qty === ''),
					'UoM': !e.uom
				})
			}
			if(!e.jenis) this.errorRequiredItemList[i]['Jenis'] = true
			if(!e.productLayanan) this.errorRequiredItemList[i]['Product Layanan'] = true
			if((e.qty === null || e.qty === '')) this.errorRequiredItemList[i]['Qty'] = true
			if(!e.uom) this.errorRequiredItemList[i]['UoM'] = true
		},
		async setErrorItemList() {
			if(this.errorRequiredItemList.some(el => Object.values(el).includes(true))) {
				this.errorRequiredItemList.forEach((e) => {
					return Object.keys(e).forEach((keys) => {
						e[keys] = false
					})
				})
			}
		},
		async checkErrorValidateItemList() {
			await this.rfqItemLists.forEach(async (e, i) => {
				if(!this.errorRequiredItemList[i]) {
					this.errorRequiredItemList.push({
						'Jenis': false,
						'Product Layanan': false,
						'Qty': false,
						'UoM': false
					})
				}
				if(this.checkInput(e)) {
					if(this.checkEmptyField(e)) {
						this.setEmpty(e, i)
					}
				} else if(!this.checkInput(e) && i === 0) this.setEmpty(e, i)
			})
			const newArray = this.errorRequiredItemList.filter(function (e) {
				return e['Jenis'] ||
				e['Product Layanan'] ||
				e['Qty'] ||
				e['UoM'] 
			});
			return Object.values(this.errorRequired).every(e => !e) && newArray.length === 0
		},
		async checkErrorValidate() {
			if(!this.quotationInformation.isNewProject) {
				if(!this.quotationInformation.projectId) {
					this.errorRequired['Project ID'] = true
				} else if(!this.quotationInformation.filterVendorId) {
					this.errorRequired['Vendor'] = true
				} else if(this.rfqLocalPartnerList.length > 0 && !this.quotationInformation.filterLocalPartnerVendorId) {
					this.errorRequired['Local Partner'] = true
				}
			}
			if (!this.quotationInformation.directApproverName) {
				this.errorRequired['Direct Approver'] = true
			}
			if (!this.quotationInformation.projectName) {
				this.errorRequired['Project Name'] = true
			}
			if (!this.quotationInformation.vendorName) {
				this.errorRequired['Vendor Name'] = true
			}
			if (!this.quotationInformation.vendorEmail) {
				this.errorRequired['Vendor Email'] = true
			}
			if (!this.quotationInformation.budgetSpent) {
				this.errorRequired['Budget Spent'] = true
			}
			if (!this.quotationInformation.kodeWbsLev1) {
				this.errorRequired['Kode Wbs Level 1'] = true
			}
			if (!this.quotationInformation.judulRfq) {
				this.errorRequired['Request Title'] = true
			}
			return Object.values(this.errorRequired).every(e => !e)

		},
		async saveAsDraft() {
			try {
				//show loading
				this.modal.modalLoading = true
				const success = await this.$refs.form.validate()
				if (!success) {
					//lodaing out
					this.modal.modalLoading = false
					//show modal error rfp validate
					this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT }
					return
				}
				const response = await this.$store.dispatch(`${this.$route.params.quotationId? 'rfq/putSaveAsDraft': 'rfq/postSaveAsDraft'}`, this.$route.params.quotationId || null)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					setTimeout(async () => {
						const query = { project: 'draft' }
						this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
					}, 300)
					//show modal success save as draft rfq
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SAVE_AS_DRAFT }
					//reset state rfq store
					await this.$store.dispatch('rfq/resetState')
					await this.$store.dispatch('dashboard/resetFilterProject')
					await this.$store.dispatch('errorRfq/resetState')	
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//close loading when error
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		async submitQuotation() {
			try {
				//show loading
				this.modal.modalLoading = true
				const success = await this.$refs.form.validate()
				const checkErrorValidate = await this.checkErrorValidate()
				const checkErrorValidateItemList = await this.checkErrorValidateItemList()
				if (!success || !checkErrorValidate || !checkErrorValidateItemList) {
					//lodaing out
					this.modal.modalLoading = false
					//show modal error rfp validate
					this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT }
					return
				}
				const response = await this.$store.dispatch(`${this.$route.params.quotationId? 'rfq/putSubmit': 'rfq/postSubmit'}`, this.$route.params.quotationId || null)
				if (response?.status < 300 && response?.status >= 200) {
					setTimeout(() => {
						if (this.$route.name === EDITRFQ) {
							this.$router.push(`/${PATH_ROOT}/quotation-detail/${this.$route.params.quotationId}`).catch(() => ({}))
						} else {
							this.$router.push({ path: `/${PATH_ROOT}`, query: { project: 'outstanding' } }).catch(() => ({}))
						}
					}, 300)
					this.$store.dispatch('rfq/resetState')
					this.$store.dispatch('errorRfq/resetState')
					this.$store.dispatch('dashboard/resetFilterProject')
					//loading out
					this.modal.modalLoading = false
					//show modal success submit rfq
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SUBMIT }
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
				
			} catch (error) {
				//close loading when error
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		async cancelQuotation() {
			setTimeout(() => {
				this.$router.go(-1)
			}, 200)
			await this.$store.dispatch('rfq/resetState')
			await this.$store.dispatch('errorRfq/resetState')
		},
		changeCollapse(e, key) {
			this.collapse[key] = !this.collapse[key]
		},
		changeIsOpen(val, key) {
			if (key === 'modalCancel') {
				this.isOpenModalCancelQuotation = val
			}
			if (key === 'modalSaveAsDraft') {
				this.isOpenModalSaveAsDraft = val
			}
			if (key === 'modalSubmit') {
				this.isOpenModalSubmit = val
			}
		}

	},
	async mounted() {
		this.$store.dispatch('dashboard/getUserDetail');
		await this.$store.dispatch('dashboard/getMenu')
		if (!this.menuRFQ?.canCreate) {
			this.$store.commit('projectLog/SET_CAN_ACCESS', false)
		}
	},
	components: {
		MainLayout,
		QuotationInformation,
		ItemList,
		ModalConfirm,
	},
};
</script>
