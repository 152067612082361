<template>
  <div class="flex flex-col">
    <label
      class="flex items-center text-sm text-gray truncate"
      :class="{ 'text-error': error, 'mb-1': label }"
    >
      {{ label }} <span v-if="mandatory" class="text-error">*</span>
    </label>
    <div class="static text-sm w-full">
      <button
        class="flex items-center justify-between px-3 py-2 w-full text-lg rounded-md border"
        :class="{
          'bg-gray-lightest text-gray-darkest cursor-not-allowed': disabled,
          'text-gray-lightest': disabled,
          'bg-white border-primary focus:shadow-secondary-lg focus:border-secondary':
            !disabled && !noBorder,
          'border-error': error,
          'hover:shadow-primary-sm': !noBorder && !disabled,
          'text-base': size != 'large',
          'h-14': size == 'large',
          'h-11': size == 'small',
        }"
        @click="
          disabled
            ? (isOptionsExpanded = false)
            : (isOptionsExpanded = !isOptionsExpanded)
        "
      >
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          v-if="iconLeft"
        >
          <span :class="iconLeft"></span>
        </div>
        <span
          class="text-base"
          :class="{
            'pl-6': iconLeft,
            'cursor-not-allowed': disabled,
            'text-gray-light': value === '' || value === undefined,
            'text-gray': value !== '',
          }"
        >
          <slot name="selected" :slot-props="selected">{{
            selected || placeholder
          }}</slot>
        </span>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
          :class="!readOnly && isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <transition
        enter-active-class="transform transition duration-500 ease-custom"
        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-300 ease-custom"
        leave-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <ul
          v-show="!readOnly && isOptionsExpanded"
          class="absolute mb-4 rounded-md overflow-hidden bg-white z-50 w-40"
          :class="{
            'shadow-primary-sm':
              !disabled && (color == undefined || color == 'primary'),
            'shadow-secondary-sm': !disabled && color == 'secondary',
            'shadow-tertiary-sm': !disabled && color == 'tertiary',
          }"
        >
          <li
            v-for="(v, i) in options"
            :key="i"
            class="px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white"
            :value="typeof v === 'object' ? v.value : v"
            :label="label"
            @click="setOption(v)"
          >
            <slot name="option" :slot-props="v">{{
              typeof v === "object" ? v.text : v
            }}</slot>
          </li>
        </ul>
      </transition>
      <div
        v-if="this.$slots['message'] != undefined"
        class="text-xs mt-1"
        :class="[{ 'text-error': error }]"
      >
        <slot name="message"></slot>
      </div>
      <div class="text-xs text-gray mt-1" v-if="hint && !dense">
        {{ hint }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "large",
    },
    disabled: Boolean,
    noBorder: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary'
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    hint: String,
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array,
    value: {
      type: [Object, String],
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  name: "SelectRfq",
  data() {
    return {
      isOptionsExpanded: false,
      selected: typeof this.value === "object" ? this.value.text : this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setOption(v) {
      this.isOptionsExpanded = false;
      this.computedValue = typeof v === "object" ? v.value : v;
      this.selected = typeof v === "object" ? v.text : v;
      this.$emit("change", v);
      this.$emit("input", this.value);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOptionsExpanded = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
