<template>
  <div class="flex flex-wrap gap-6">
    <radio-gista :name="name" 
      :disabled="disabled" 
      v-for="(n, i) in options" :key="i" 
      :modelValue="selected" 
      :nativeValue="nativeValueMethod(n)" 
      @change="changeInput">
      <p class="text-gray">
        {{ typeof n === "object" ? n[textField] : n }}
      </p>
    </radio-gista>
  </div>
</template>
<script>
export default {
  name: "GroupRadioButton",
  props: {
    name: String,
    gap: String,
    options: Array,
    selected: String,
    disabled: Boolean,
    textField: String,
    textField2: String,
  },
  computed: {
  },
  methods: {
    nativeValueMethod(n) {
      return typeof n === "object" ? n[this.textField2] : n
    },
    changeInput(e) {
      this.$emit('change', e)
    }
  },
}
</script>